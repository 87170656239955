.App {
  text-align: center;
  background-color: #ffffff;
  /* #282c34 */
}

.App-logo-header {
  width: 100px;
  height: 40px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.download-logo {
  pointer-events: none;
  min-width: 150px;
  height: 50px;
}

.facebook-logo{
  display: flex;
  flex-direction: row-reverse;
}

.ziba-logo {
  /* float: left; */
  display: flex;
}

.ziba-body-content{
  padding: 30px 50px;
  display: flex;
}

.iconSize{
  padding-left: 10px;
  width: 40px;
  height: 40px;
}

@media only screen and (max-width: 800px) {
  .ziba-body-content{
    display: block;
  }
}
@media only screen and (max-width: 437px) {
  .App-link{
    margin: 0 !important;
    width: 100%;
  }
  .App-link:first-child{
    margin-bottom: 20px !important;
  }

  .facebook-logo>.App-link {
    min-width: unset !important;
    margin: 0 !important;
  }  

  .google{
    background-size: auto 110% !important;
  }

  .apple{
    background-size: auto 100% !important;
  }
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
  }
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  margin-top: 1vh;
  margin-left: 1vh;
  margin-right: 1vh;
  /* padding-top: 20px; */
  padding-bottom: -110px;
  /* padding-left: 20px; */
  text-align: left;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

.App-link {
  color: #61dafb;
  display: flex;
  align-items: center;
  float: left;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-width: 150px;
  height: 50px;
}

.facebook-logo>.App-link {
  min-width: unset !important;
  margin: 0 !important;
}

.App-link:first-child{
  margin-right: 20px;
}

.google {
  background-image: url('/download-google.png');
}

.apple{
  background-image: url('/apple-link.png');
}

.App-content {
  background-color: #ffffff;
  margin-bottom: 20px;
  font-size: calc(10px + 2vmin);
  color: white;
  float: left;
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #ffffff;
}